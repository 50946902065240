<template>
  <v-row no-gutters :justify="justify" align="center" class="pa-3">
    <img
      src="@/assets/logo.svg"
      alt="Mercedes-Benz"
      contain
      height="30"
      width="30"
      v-if="!source.isMe"
      class="mr-3"
    />
    <v-card class="rounded pa-4" elevation="0" max-width="70%">
      <v-progress-circular indeterminate color="primary" v-if="isLoading" />
      <div v-if="preText">{{ preText }}</div>
      {{ source.tts_text }}
      <div v-if="audioSources.length > 0" class="py-2">
        <audio controls controlsList="nodownload">
          <source v-for="(audio, index) in audioSources" :key="index" :src="audio" type="audio/mpeg" />
          Your browser does not support the audio tag.
        </audio>
      </div>
      <div v-if="postText">{{ postText }}</div>
    </v-card>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    source: {
      type: Object
    }
  },
  data: () => ({}),
  updated() {
    if (this.source.isMe) return;
    if (!this.textSpeech) return;
    this.$nextTick(() => {
      this.addAudio({
        pre: this.preText,
        tts: this.source.tts_text,
        post: this.postText,
        //urls: this.audioSources
      }).finally(() => {
        console.log("Playing finished");
      });
    });
  },
  computed: {
    ...mapState("setting",["textSpeech"]),
    justify() {
      return !this.source?.isMe ? "start" : "end";
    },
    isLoading() {
      return !!this.source.isLoading;
    },
    preText() {
      return this.source?.data?.pre_tts_text || "";
    },
    postText() {
      return this.source?.data?.post_tts_text || "";
    },
    audioSources() {
      const audioData = this.source?.data?.audio_data || [];
      let audioQueue = [];
      if (Array.isArray(audioData)) {
        audioData.forEach((d) => {
          if (d.toString().includes(".mp3")) {
            audioQueue.push(...d.toString().split(","));
          } else {
            audioQueue.push("data:audio/mp3;base64," + d);
          }
        });
        return audioQueue;
      }
      if (typeof audioData === "string") {
        const prefix = audioData.includes(".mp3")
          ? ""
          : "data:audio/mp3;base64,";
        return [prefix + audioData];
      }
      return audioQueue;
    }
  },
  methods: {
    ...mapActions("player", ["addAudio"])
  }
};
</script>
